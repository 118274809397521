<template>
	<v-container class="pa-0 ma-0">
    <v-app-bar flat color="topBar">
        <v-tabs hide-slider show-arrows>
            <!-- <v-tab :ripple="false" text exact v-on="on" :to="{name: 'Restaurantes'}">
              <v-icon left v-text="'mdi-arrow-left'" class="mr-2"></v-icon>
              <span class="blue-grey--text" v-text="'Atrás'"></span>
            </v-tab>    -->
            <v-menu bottom origin="center center" transition="slide-x-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-tab :ripple="false" text exact v-on="on">
                  <v-icon left v-text="'mdi-clipboard-text-outline'" class="mr-2"></v-icon>
                  <span class="blue-grey--text" v-text="'Carta'"></span>
                </v-tab>
              </template>
              <v-list dense>
                <v-list-item @click="$router.push({name: 'menu_list'})">
                 <v-list-item-title class="blue-grey--text text--darken-1">-Lista</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list dense>
                <v-list-item @click="$router.push({path: `/tc-admin/restaurante/${$route.params.id}/administrar/carta/crear`})">
                 <v-list-item-title class="blue-grey--text text--darken-1">-Nuevo</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu bottom origin="center center" transition="slide-x-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-tab :ripple="false" text exact v-on="on">
                  <v-icon left v-text="'mdi-pasta'" class="mr-2"></v-icon>
                  <span class="blue-grey--text" v-text="'Platos'"></span>
                </v-tab>
              </template>
              <v-list dense>
                <v-list-item  @click="$router.push({name: 'platos'})">
                 <v-list-item-title class="blue-grey--text text--darken-1">-Lista</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list dense>
                <v-list-item  @click="$router.push({path: `/tc-admin/restaurante/${$route.params.id}/administrar/plato/crear`})">
                 <v-list-item-title class="blue-grey--text text--darken-1">-Nuevo</v-list-item-title>
                </v-list-item>
              </v-list>
               <v-list dense>
                <v-list-item @click="$router.push({name: 'Categorías'})">
                 <v-list-item-title class="blue-grey--text text--darken-1">-Categorías</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu bottom origin="center center" transition="slide-x-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-tab :ripple="false" text exact v-on="on">
                  <v-icon left v-text="'mdi-image-album'" class="mr-2"></v-icon>
                  <span class="blue-grey--text" v-text="'Promociones'"></span>
                </v-tab>
              </template>
              <v-list dense>
                <v-list-item @click="$router.push({name: 'promociones'})">
                 <v-list-item-title class="blue-grey--text text--darken-1">-Lista</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list dense>
                <v-list-item @click="$router.push({path: `/tc-admin/restaurante/${$route.params.id}/administrar/promocion/crear`})">
                 <v-list-item-title class="blue-grey--text text--darken-1">-Nuevo</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu bottom origin="center center" transition="slide-x-transition" offset-y>
              <template v-slot:activator="{ on }">
                <v-tab :ripple="false" text exact v-on="on">
                  <v-icon left v-text="'mdi-image-album'" class="mr-2"></v-icon>
                  <span class="blue-grey--text" v-text="'Horarios'"></span>
                </v-tab>
              </template>
              <v-list dense>
                <v-list-item @click="$router.push({name: 'horarios'})">
                 <v-list-item-title class="blue-grey--text text--darken-1">-Lista</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list dense>
                <v-list-item @click="$router.push({path: `/tc-admin/restaurante/${$route.params.id}/administrar/promocion/crear`})">
                 <v-list-item-title class="blue-grey--text text--darken-1">-Nuevo</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tab :ripple="false" text exact :to="{name: 'Configuración'}">
              <v-icon left v-text="'mdi-cog-transfer-outline'" class="mr-2"></v-icon>
              <span class="blue-grey--text" v-text="'Configuracion'"></span>
            </v-tab>
        </v-tabs>
    </v-app-bar>

    <v-container fluid tag="section" class="pa-0 ma-0">
      <!--Rutas de Configuracion -->
      <router-view />
    </v-container>

  </v-container>
</template>
<script>
  export default {
  	data(){
  		return{

  		}
  	}
  }
 </script>
